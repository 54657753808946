import React from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import CancelSubscription from "./CanceledSubscription";
const CanceledSubscriptionContainer = () => {
  return (
    <>
      <CancelSubscription />
      <CarlsbergPoland />
    </>
  );
};

export default CanceledSubscriptionContainer;
