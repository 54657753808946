import React from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import UnsubscribeUserNotFound from "./unsubscribeUserNotFound";
const UnsubscribeUserNotFoundContainer = () => {
  return (
    <>
      <UnsubscribeUserNotFound />
      <CarlsbergPoland />
    </>
  );
};

export default UnsubscribeUserNotFoundContainer;
