import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "@atlaskit/css-reset";
import Homepage from "./Homepage";
import Navbar from "./Navbar";
import Footer from "./Footer";
import NotFound from "./NotFound";
import ConfirmSubscriptionContainer from "../features/confirmSubscription/confirmSubscription.container";
import CookiesDetailsContainer from "../features/cookiesDetails/cookiesDetails.container";
import { SubPage } from "../features/SubPage/SubPage";
import ConfirmEmailContainer from "../features/confirmEmail/confirmEmail.container";
import CanceledSubscriptionContainer from "../features/canceledSubscription/canceledSubscription.container";
import MessageSentContainer from "../features/messageSent/messageSent.container";
import CancelSubscriptionContainer from "../features/cancelSubscription/cancelSubscription.container";
import CancelSubscriptionECheckContainer from "../features/cancelSubscriptionEmailCheck/cancelSubscriptionECheck.container";
import subscriptionFailedContainer from "../features/subscriptionFailed/subscriptionFailed.container";
import UnsubscribeTokenExpiredContainer from "../features/unsubscribeTokenExpired/unsubscribeTokenExpired.container";
import UnsubscribeUserNotFoundContainer from "../features/unsubscribeUserNotFound/unsubscribeUserNotFound.container";
import emailExistContainer from "../features/emailExist/emailExist.container";
import CookiesAcceptation from "../features/cookiesAcceptation/CookiesAcceptation";

const App = () => {
  const [forceCookiesSettings, setForceCookiesSettings] = useState(false);
  return (
    <>
      <Router>
        <GlobalStyle />
        <Wrapper>
          <div className="container-fluid">
            <Navbar />
            <CookiesAcceptation
              forceCookiesSettings={forceCookiesSettings}
              setForceCookiesSettings={setForceCookiesSettings}
            />
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route
                exact
                path="/confirmSubscription"
                component={ConfirmSubscriptionContainer}
              />
              <Route
                exact
                path="/confirmEmail"
                component={ConfirmEmailContainer}
              />
              <Route
                exact
                path="/canceledSubscription"
                component={CanceledSubscriptionContainer}
              />
              <Route
                exact
                path="/cancelSubscription"
                component={CancelSubscriptionContainer}
              />
              <Route
                exact
                path="/cancelSubscriptionEmail"
                component={CancelSubscriptionECheckContainer}
              />
              <Route
                exact
                path="/messageSent"
                component={MessageSentContainer}
              />
              <Route
                exact
                path="/subscriptionFailed"
                component={subscriptionFailedContainer}
              />
              <Route
                exact
                path="/unsubscribeTokenExpired"
                component={UnsubscribeTokenExpiredContainer}
              />
              <Route
                exact
                path="/unsubscribeUserNotFound"
                component={UnsubscribeUserNotFoundContainer}
              />
              <Route
                exact
                path="/emailExisting"
                component={emailExistContainer}
              />
              {/* <Route
                exact
                path="/cookies-policy"
              >
                <SubPage pageKey="policy_cookies_1" />
              </Route>
              <Route
                exact
                path="/privacy-policy"
              >
                <SubPage pageKey="policy_privacy_1" />
              </Route>
              <Route
                exact
                path="/contact"
              >
                <SubPage pageKey="contactPage_1" />
              </Route>
              <Route
                exact
                path="/regulations"
              >
                <SubPage pageKey="rules_1" />
              </Route> */}
              <Route component={NotFound} />
            </Switch>
            <Footer setForceCookiesSettings={setForceCookiesSettings} />
          </div>
        </Wrapper>
      </Router>
    </>
  );
};

export default App;

const GlobalStyle = createGlobalStyle`
html {
    font-size: 70.5%;
    scroll-behavior: smooth;
}
body {
    background: #c4cfd8;
    min-width:300px;
}
.nav-global {
    padding: 15px 0;
    background: #fff;
}
svg {
    height: 20px;
    width: 20px;
    display: block;
    float: left;
    margin-right: 5px;
    margin-top: -2px;
}
.container-fluid {
    @media (max-width: 576px) {
        padding: 0;
    }
}
.cp-icon {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    fill: #284a41;
}
a {
    :focus {
        outline: none;
    }
}
.news-link {
    font-size: 14px;
    font-family: "ThemeFont-Tertiary", sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: #284a41;
    margin-top: 10px;
    :hover {
        text-decoration: none;
        color: #284a41;
    }
}
.carousel-indicators li {
    margin: 0 5px;
    height: 5px;
    width: 50px;
    background-color: #284a41;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: none;
}
`;

const Wrapper = styled.div`
  background: #fff;
  max-width: 1440px;
  padding: 0 10px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @media (max-width: 576px) {
    padding: 0;
  }
`;
