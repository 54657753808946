import React from "react";
import styled from "styled-components";
import Newsletter from "./Newsletter";
import { bgList, logos } from "../../images/index";

const NewsletterContainer = () => {
  return (
    <Jumbotron>
      <Wrapper id="backg" className="hehe no-gutters overflow-hidden">
        <Background>
          {bgList &&
            bgList.map((image, index) => (
              <BackItem key={index}>
                <BackItemImage src={image} alt="Background block image" />
              </BackItem>
            ))}
        </Background>
        <Newsletter />
      </Wrapper>
      <Logos src={logos} style={{ width: "100%" }}></Logos>
    </Jumbotron>
  );
};

export default NewsletterContainer;

//styling

const Jumbotron = styled.div`
  background: #e8f3e0;
  min-height: 646px;
  width: 100%;
  @media (max-width: 450px) {
    min-height: 326px;
  }
`;

const Logos = styled.img`
  margin: auto;
  margin-bottom: -50px;
`;

const Wrapper = styled.div`
  background-color: #c8d0d9;
  width: 100%;
  display: flex;
  padding: 15px;
  @media (max-width: 450px) {
    min-height: 326px;
  }

  @media (max-width: 1180px) {
    flex-direction: column-reverse;
    height: auto;
  }

  @media (max-width: 675px) {
    padding: 10px;
  }

  @media (max-width: 370px) {
    padding: 3px;
  }
`;

const Background = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style-type: none;
  height: fit-content;
  align-items: end;
  gap: 5px;
  padding: 0;

  @media (max-width: 1430px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1180px) {
    margin: 20px auto 20px !important;
  }
`;

const BackItem = styled.li`
  width: 257px;
  overflow: hidden;
  display: grid;
  place-items: center;

  ${BackItem}:nth-child(5) {
    grid-column: 2/4;
    width: 510px;
  }

  @media (max-width: 1430px) {
    ${BackItem}:nth-child(5) {
      grid-column: 1/3;
    }
  }

  @media (max-width: 1180px) {
    ${BackItem}:nth-child(7), ${BackItem}:nth-child(6) {
      margin-bottom: 0px;
    }
  }

  @media (max-width: 600px) {
    width: 170px;

    ${BackItem}:nth-child(5) {
      width: 340px;
    }
  }

  @media (max-width: 370px) {
    width: 140px;

    ${BackItem}:nth-child(5) {
      width: 280px;
    }
  }
`;

const BackItemImage = styled.img`
  height: 270px;
  object-fit: scale-down;
  width: 100%;
  @media (max-width: 600px) {
    height: 170px;
  }

  @media (max-width: 370px) {
    height: 140px;
  }
`;
