import React from "react";
import styled from "styled-components";

const CookiesDetails = () => {

  return (
    <Jumbotron>
      <Wrapper className="row no-gutters">
        <div className="col-sm-12 col-md-3">

        </div>
        <div className="col-sm-12 col-md-6">
          <HB>Polityka cookies</HB>
          <H>Zgoda</H>
          <P>Korzystając z tej strony (<i>www.carlsbergpolska-biznesportal.pl</i>) użytkownik wyraża zgodę 
            na wykorzystanie plików cookies zgodnie z niniejszą polityką plików cookies. W tym 
            celu, przy pierwszej wizycie na Stronie otworzy się nowe okno dialogowe ze stosowną 
            informacją. Mimo iż nie będzie się ono pojawiać przy kolejnych wizytach, użytkownik 
            może zawsze wycofać swoją zgodę, postępując zgodnie z poniższymi instrukcjami.
          </P>
          <Divider/>
          <H>Wyłączenie plików cookie</H>
          <P>Jeśli użytkownik nie zgadza się na wykorzystanie plików cookies na Stronie, może 
            je wyłączyć, postępując zgodnie z instrukcjami dla swojej przeglądarki przedstawionymi
            <b><i> http://allaboutcookies.org</i></b>. Należy zwrócić uwagę na to, że po wyłączeniu plików 
            cookies niektóre funkcje Strony oraz usługi świadczone na Stronie będą działać w 
            ograniczony sposób.
          </P>
          <Divider/>
          <P>Jeśli organizacja umieszczająca pliki cookies dostarcza automatyczne narzędzie 
            wyłączające pliki cookies, wóczas poniżej podajemy nazwę takiej organizacji, 
            kategorię stosowanych przez nią plików cookies oraz link do jej automatycznego 
            narzędzia wyłączającego. W pozostałych przypadkach, podajemy same nazwy plików 
            cookies oraz ich źródło na dzień niniejszej polityki plików cookies, tak aby 
            użytkownik mógł je łatwo zidentyfikować oraz wedle uznania wyłączyć w ustawieniach 
            swojej przeglądarki.
          </P>
          <Divider/>
          <P>Niektóre przeglądarki posiadają opcję zasygnalizowania tego, że użytkownik nie życzy 
            sobie śledzenia jego aktywności na przeglądanych stronach internetowych. Wyłączenie 
            śledzenia może zakłócić niektóre funkcje Strony oraz usługi świadczone na Stronie.
          </P>
          <Divider/>
          <P>Po pierwszej wizycie na Stronie możemy zmienić wykorzystywane pliki cookies. 
            Niniejsza polityka plików cookie zawsze udostępnia użytkownikowi informacje na temat 
            tego, kto i w jakim celu umieszcza pliki cookie oraz umożliwia użytkownikowi ich 
            wyłączenie, zatem należy ją od czasu do czasu ponownie przeczytać.
          </P>
          <Divider/>
          <H>Czym są pliki cookie?</H>
          <P>Pliki cookie to pliki tekstowe zawierające małe ilości informacji, które są pobierane 
            na urządzenie użytkownika podczas przeglądania strony. Pliki cookie są następnie 
            wysyłane z powrotem do pierwotnej domeny internetowej w momencie kolejnej wizyty na 
            takiej domenie. Większość stron internetowych zawiera elementy pochodzące z wielu domen 
            internetowych, więc odwiedzając Stronę, przeglądarka użytkownika może otrzymać pliki 
            cookie pochodzące z kilku źródeł.
          </P>
          <Divider/>
          <P>Pliki cookie są przydatne, ponieważ umożliwiają stronie rozpoznanie urządzenia użytkownika. Pliki cookie pozwalają użytkownikowi na skuteczną nawigację pomiędzy stronami, zapamiętywanie preferencji oraz ogólnie zwiększają zadowolenie użytkownika. Mogą one również służyć dopasowaniu treści reklamowej do zainteresowań użytkownika poprzez śledzenie przeglądanych przez niego stron.</P>
          <Divider/>
          <P>Tymczasowe pliki cookie są automatycznie usuwane po zamknięciu przeglądarki, a stałe pliki cookie pozostają na urządzeniu użytkownika również po zamknięciu przeglądarki (na przykład, aby pamiętać preferencje użytkownika przy jego kolejnej wizycie na stronie).</P>
          <Divider/>
          <H>Jakie rodzaje plików cookie są wykorzystywane przez Carlsberg?</H>
          <P>Poniżej przedstawiamy kategorię plików cookie Carlsberg, jego spółek stowarzyszonych oraz dostawców.  </P>
          <Divider/>
          <P><u>Niezbędne pliki cookies</u></P>
          <Divider/>
          <P>Te pliki są niezbędne w celu umożliwienia użytkownikowi poruszania się po Stronie oraz krzystania z jej funkcji. Bez tych plików cookies nie można dostarczyć żądanych usług (takich jak nawigowanie pomiędzy stronami, korzystanie ze sklepu internetowego lub usługi e-fakturowania). </P>
          <Divider/>
          <P>Obecnie Strona wykorzystuje poniższe, niezbędne pliki cookies:</P>
          <Divider/>
          <P><u>Local Storage</u></P>
          <Divider/>
          <P>Na stronie wykorzystujemy mechanizm Local Storage. Local Storage przechowuje informacje zwiększające wygodę korzystania ze strony oraz informacje niezbędne do jej poprawnego działania. Local Storage jest podobne do plików cookies przeglądarki. Same w sobie nie mogą one wpływać na ani zmieniać danych na komputerze użytkownika. Podobnie jak inne pliki cookies, mogą one mieć dostęp jedynie do informacji identyfikacyjnych, które użytkownik udostępni na Stronie i nie mają do nich dostępu pozostałe strony. Strona wykorzystuje następujące informacje zawarte w Local Storage: </P>
          <ul>
            <Li>cookiesAccepted - informacja potrzebna dla okna akceptacji cookies, czy użytkownik zaakceptował politykę cookies</Li>
            <Li>randid - unikalne id przeglądarki generowane losowo przy pierwszej wizycie, podstawowy element Local Storage </Li>
            <Li>randomColor - informacja potrzebna do wyświetlania losowych obrazów w tle</Li>
            <Li>email - adres e-mail użytkownika</Li>
          </ul>
          <Divider/>
          <H>HTML email web beacons</H>
          <P>Nasze emaile mogą zawierać pojedyncze, unikatowe dla kampanii „web beacon pixel”, które informują nas o tym, kiedy emaile zostały otwarte oraz sprawdzają, czy linki zawarte w emailu zostały otwarte. Możemy wykorzystywać te informacje w celach takich jak m. in. do określenia tego, czy nasze emaile są bardziej interesujące dla użytkownika oraz zapytania użytkowników, którzy nie otwierają naszych emaili o to, czy chcą je nadal otrzymywać. Pixel zostaje usunięty wraz z usunięciem emaila. Jeśli użytkownik nie chce, aby pixel został pobrany na jego urządzenie, powinien wybrać opcję otrzymywania emaili od nas w formie zwykłego tekstu, a nie HTML. Niektóre z podstron na Stronie mogą również zawierać web beacons, które pozwalają nam na liczenie ilości odwiedzających użytkowników, którzy wyświetlili nasze strony. Pozwalają one nam na opracowanie informacji statystycznych na temat działań i funkcji, które są najbardziej interesujące dla klientów w celu dostarczania bardziej spersonalizowanej treści. Ogólnie, nie służą one do uzyskiwania informacji umożliwiających identyfikację użytkownika bez jego zgody.</P>
          <Divider/>
          <P>Tagi pikselowe mogą być udostępniane osobom trzecim, które bezpośrednio obsługują nasze działania promocyjne oraz rozwój strony. Na przykład, aby zmierzyć efektywność naszych reklam, osoby trzecie, łącznie z serwisem Facebook, mogą wykorzystywać cookies, web beacons oraz pozostałe technologie przechowywania celem zbierania lub otrzymywania informacji ze Strony oraz z innych miejsc w Internecie oraz wykorzystywać takie informacje celem świadczenia usług pomiarowych oraz personalizowania reklam na swoich własnych platformach. Znajdują się tutaj informacje na temat tego, w jaki sposób można wycofać się ze zbierania i wykorzystywania informacji do personalizowania reklam przez osoby trzecie (linki do http://www.aboutads.info/choices oraz http://www.youronlinechoices.eu/).</P>
          <Divider/>
          <H>Wykorzystanie adresów IP i rejestrów stron</H>
          <P>Możemy również wykorzystywać adres IP oraz typ wyszukiwarki użytkownika celem ułatwienia diagnozowania problemów z naszym serwerem, zarządzania Stroną oraz ulepszania oferowanych usług. Adres IP to numeryczny kod, który identyfikuje komputer użytkownika w Internecie. Adres IP użytkownika może być również wykorzystywany do zbierania szerokich informacji demograficznych.</P>
          <Divider/>
          <P>Może sprawdzać IP celem określenia, z jakiej domeny użytkownik wchodzi (np. google.com), aby w bardziej dokładny sposób poznać dane demograficzne na temat naszych użytkowników.</P>  
          <Divider/>
          <H>Polityka plików cookies nie dotyczy stron osób trzecich</H>
          <P>Należy mieć na uwadze to, że niniejsza polityka plików cookie nie dotyczy, a my nie odpowiadamy za praktyki dotyczące ochrony prywatności wykorzystywane na stronach osób trzecich, do których mogą prowadzić linki znajdujące się na Stronie.</P>
          <Divider/>
          <H>Zmiany w polityce plików cookies</H>
          <P>Możemy aktualizować niniejszą politykę plików cookies i zachęcamy użytkowników do ponownego przeczytania niniejszej polityki od czas do czasu, aby być na bieżąco ze sposobem wykorzystywania przez nas plików cookies. Niniejsza polityka plików cookies została zaktualizowana w dniu 18 czerwca 2020r. </P>
        </div>
      </Wrapper>
    </Jumbotron>
  );
};

export default CookiesDetails;

const Jumbotron = styled.div`
    background: white;
    padding-top: 80px;
`;

const Wrapper = styled.div`
  background-color: white;
`;
const P = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #284a41;
`;
const Li = styled.li`
  font-size: 16px;
  color: #284a41;
`;
const H = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #284a41;
  display: block;
  margin-bottom: 15px;
  margin-top: 15px;
`;
const HB = styled.span`
  font-size: 17px;
  font-weight: bold;
  color: #284a41;
  display: block;
  margin-bottom: 25px;
  margin-top: 20px;
`;
const Divider = styled.div`
  height: 15px;
`;