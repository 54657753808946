import React from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import UnsubscribeTokenExpired from "./unsubscribeTokenExpired";
const UnsubscribeTokenExpiredContainer = () => {
  return (
    <>
      <UnsubscribeTokenExpired />
      <CarlsbergPoland />
    </>
  );
};

export default UnsubscribeTokenExpiredContainer;
