import React from "react";
import styled from "styled-components";
import { bgListTemp } from "../../images/index";

const ConfirmEmail = () => {
  // random background
  const rollBg = () => {
    let backs = bgListTemp;
    let idx = Math.floor(Math.random() * backs.length);
    localStorage.setItem("randomColor", idx);
    return backs[idx];
  };
  return (
    <Jumbotron>
      <Wrapper
        className="row no-gutters"
        style={{ backgroundImage: `url(${rollBg()})` }}
      >
        <Column className="col-md-5 col-xs-10 overflow-hidden position-relative "></Column>
        <RightColumn className="col-md-6 col-xs-10 offset-lg-1">
          <TextWrapper>
            <H2>Jeszcze jeden krok - potwierdź subskrypcję</H2>
            <Paragraph className="brand-slider__description">
              Aby potwierdzić subskrypcję wejdź na wskazaną skrzynkę mailową i
              kliknij na link aktywacyjny.
            </Paragraph>
            <Paragraph2>
              W przypadku problemów z odnalezieniem wiadomości, prosimy o
              dokładne sprawdzenie skrzynki mailowej, w tym zakładek
              spam/oferty.
            </Paragraph2>
          </TextWrapper>
        </RightColumn>
      </Wrapper>
    </Jumbotron>
  );
};

export default ConfirmEmail;

const Jumbotron = styled.div`
    background: #e8f3e0;
    min-height: 646px
    width: 100%
    @media (max-width: 450px) {
      min-height: 326px;
    }
`;

const Wrapper = styled.div`
  min-height: 1103px;
  @media (max-width: 450px) {
    min-height: 326px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: -220px;
  text-align: center;
  max-width: 550px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px 30px;
  border-radius: 5px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.5);
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;
const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 45px !important;
`;

const Paragraph = styled.p`
  font-size: 2rem;
  line-height: 100%;
  color: #284a41;
  margin-bottom: 20px;
`;
const Paragraph2 = styled.p`
  font-size: 1.5rem;
  line-height: 100%;
  color: #284a41;
  margin-bottom: 15px;
`;
const H2 = styled.p`
  font-size: 2.8rem;
  margin-bottom: 15px;
  font-weight: 700;
  line-height: 120%;
  color: #284a41;
`;
