import React from "react";
import styled from "styled-components";
import "./Footer.css";
import { host } from "../services/api/host";

const Footer = ({ setForceCookiesSettings }) => {
  let paragraph;
  if (window.location.pathname === "/cookies-policy") {
    paragraph = (
      <Paragraph>
        <a className="link" href="/" style={{width: "60%"}}>
          Wróć do strony głównej
        </a>
      </Paragraph>
    );
  } else {
    paragraph = (
      <Paragraph>
        <>
          Jesteś subskrybentem Newsletter Carlsberg Polska i chcesz się wypisać,
          kliknij{" "}
          <a
            className="link"
            href="/cancelSubscriptionEmail"
            style={{ color: "#0052CC" }}
          >
            tutaj.
          </a>
        </>
      </Paragraph>
    );
  }
  return (
    <Wrapper>
      <Div>
        {paragraph}

        <Divider />
        <Divider />

        <Paragraph>Carlsberg Polska </Paragraph>
        <Paragraph>ul. Krakowiaków 34,</Paragraph>
        <Paragraph>02-255 Warszawa,</Paragraph>
        <Divider />
        <Paragraph>Telefon + 22 543 15 00 </Paragraph>
        <Paragraph>info@carlsberg.pl</Paragraph>
        <Divider />

        <Divider />
      </Div>
      <nav className="footer-menu">
        {/* <a className="link" href="/regulations">
          Regulamin
        </a>
        <a className="link" href="/privacy-policy">
          Polityka Prywatoności
        </a>
        <a className="link" href="/cookies-policy">
          Polityka Cookies
        </a>
        <a className="link" href="/contact">
          Kontakt
        </a> */}
        <a className="link" target="_blank" href={`${host}/Admin/publicFooter/getLinksForRegulations/link/rules_1`}>
          Regulamin
        </a>
        <a className="link" target="_blank" href={`${host}/Admin/publicFooter/getLinksForRegulations/link/policy_privacy_1`}>
          Polityka Prywatności
        </a>
        <a className="link" target="_blank" href={`${host}/Admin/publicFooter/getLinksForRegulations/link/policy_cookies_1`}>
          Polityka Cookies
        </a>
        <a className="link" target="_blank" href={`${host}/Admin/publicFooter/getLinksForRegulations/link/contactPage_1`}>
          Kontakt
        </a>
        <a
          href="#"
          className="link"
          onClick={() => {
            setForceCookiesSettings(true);
          }}
        >
          Zarządzaj plikami cookies
        </a>
      </nav>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  position: relative;
`;
const Divider = styled.div`
  height: 20px;
`;
const Div = styled.div`
  margin-top: 60px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Paragraph = styled.p`
  color: #5a5a5a;
  font-weight: bold;
  margin-top: 12px;
  text-align: center;

  @media (max-width: 575px) {
    padding: 0 10px;
  }
`;
