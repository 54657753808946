import React from "react";
import styled from "styled-components";
import { bgListTemp } from "../../images/index";

const UnsubscribeUserNotFound = () => {
  // random background
  const rollBg = () => {
    let backs = bgListTemp;
    let idx = Math.floor(Math.random() * backs.length);
    localStorage.setItem("randomColor", idx);
    return backs[idx];
  };
  return (
    <Jumbotron>
      <Wrapper
        className="row no-gutters"
        style={{ backgroundImage: `url(${rollBg()})` }}
      >
        <Column className="col-md-6 col-xs-10 overflow-hidden position-relative "></Column>
        <RightColumn className="col-md-5 col-xs-10 offset-lg-1">
          <TextWrapper>
            <Paragraph className="brand-slider__description">
              Podany adres e-mail nie istnieje w bazie danych.
            </Paragraph>
            <Button type="submit" href="/">
              Wróć na stronę główną
            </Button>
          </TextWrapper>
        </RightColumn>
      </Wrapper>
    </Jumbotron>
  );
};

export default UnsubscribeUserNotFound;

const Jumbotron = styled.div`
    background: #e8f3e0;
    min-height: 646px
    width: 100%
    @media (max-width: 450px) {
      min-height: 326px;
    }
`;

const Wrapper = styled.div`
  min-height: 1103px;
  @media (max-width: 450px) {
    min-height: 326px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  position: relative;
  top: -220px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 40px;
  text-align: center;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;
const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 45px !important;
`;

const Paragraph = styled.p`
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 150%;
  color: #284a41;
`;
const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 230px;
  height: 50px;
  background: none;
  border: 2px solid #284a41;
  color: #284a41;
  font-weight: bold;
  transition: 0.3s;
  margin: auto;
  margin-top: 25px;
  font-size: 1.4rem;
  :hover {
    background: #284a41;
    color: white;
    text-decoration: none;
  }
`;
