import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import { unsubscribeRequest } from "../../services/api/index";
import { bgListTemp } from "../../images/index";
import "../../features/newsletter/newsletter.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import img from "./../../images/back1.jpg";

var text = "";

const CancelSubscriptionECheck = (props) => {
  const [alert, setAlert] = useState("");
  const [bg, setBg] = useState("");
  const [emailVerified, setEmailVerified] = useState(null);
  const [loading, setLoading] = useState(false);

  let eMail = "";
  useEffect(() => {
    setBg(rollBg());
  }, []);
  // random background
  const rollBg = () => {
    let backs = bgListTemp;

    let idx = Math.floor(Math.random() * backs.length);

    localStorage.setItem("randomColor", idx);
    return backs[idx];
  };
  const mailChange = (e) => {
    eMail = e.target.value;
  };
  const radioChange = (e) => {
    /* let array = document.getElementsByClassName("radios");
    let area = document.getElementsByClassName("area")[0];
    text = e.target.value;
    if (array[3].checked) {
      area.disabled = false;
      text = area.value;
    } else {
      area.disabled = true;
    } */
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  return (
    <Jumbotron>
      <Wrapper
        className="row no-gutters"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <Column className="col-md-6 col-xs-10 overflow-hidden position-relative "></Column>
        <RightColumn className="col-md-6 col-xs-8  row justify-content-center">
          <Card className="text-center">
            <div className="card-body">
              <H2 className="mb-4">
                Podaj adres e-mail, który chcesz wypisać z Newslettera.
              </H2>
              <Formik
                initialValues={{
                  content: "",
                }}
                onSubmit={(fields, e) => {
                  setEmailVerified(validateEmail(eMail));
                  let validation = validateEmail(eMail);
                  if (eMail != "" && validation) {
                    setTimeout(() => {
                      localStorage.setItem("email", eMail);
                      setLoading(true);
                      setAlert("");
                      unsubscribeRequest(eMail)
                        .then(
                          (response) => (window.location.href = "/messageSent")
                        )
                        .catch(
                          (error) => (window.location.href = "/messageSent")
                        );
                    }, 0);
                  } else {
                    setAlert("Wypełnij prawidłowo pole z adresem e-mail");
                  }
                }}
              >
                {({ errors, status, touched }) => (
                  <Form onChange={radioChange}>
                    {" "}
                    <div className="form-group row">
                      <FormDiv>
                        {!props.token && (
                          <>
                            {/* <LabelEmail
                              className="unselectable"
                              htmlFor="email"
                            >
                              {" "}Adres e-mail:
                            </LabelEmail> */}
                            <Field
                              as="input"
                              name="mailContent"
                              type="text"
                              id="email"
                              placeholder="Adres e-mail"
                              className={"form-control"}
                              onChange={mailChange}
                              style={{
                                height: "35px",
                                width: "90%",
                                fontSize: "14px",
                                marginTop: "-3px",
                                marginBottom: "20px",
                                border:
                                  emailVerified === false
                                    ? "1px solid red"
                                    : "",
                              }}
                            />
                          </>
                        )}
                      </FormDiv>
                    </div>
                    {alert !== "" && <Alert>{alert}</Alert>}
                    <div className="form-group row justify-content-center">
                      <ButtonContainer>
                        <Button
                          type="submit"
                          style={
                            loading
                              ? {
                                  opacity: "0.6",
                                  backgroundColor: "transparent",
                                  mouseEvents: "none",
                                  color: "#284a41",
                                  cursor: "default",
                                }
                              : {}
                          }
                        >
                          WYŚLIJ
                        </Button>
                        <Spinner>
                          <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            visible={loading}
                            timeout={900000}
                          />
                        </Spinner>
                      </ButtonContainer>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </RightColumn>
      </Wrapper>
    </Jumbotron>
  );
};

export default CancelSubscriptionECheck;

const ButtonContainer = styled.div`
  position: relative;
`;
const Jumbotron = styled.div`
    background: #e8f3e0;
    min-height: 646px
    width: 100%;
   @media(max-width: 450px){
      min-height: 326px;
    }
`;
const Label = styled.label`
  font-size: 14px;
  position: relative;
  top: -2px;
`;
const Spinner = styled.div`
  position: absolute;
  top: 16px;
  left: 190px;
`;
const LabelEmail = styled.label`
  margin-top: 5px;
  font-size: 14px;
  position: relative;
  top: -5px;
  font-weight: bold;
  color: rgb(1, 86, 34);
`;
const Alert = styled.div`
  color: red;
  position: relative;
  top: -11px;
`;
const FormDiv = styled.div`
  text-align: left;
  margin-top: 10px;
  padding-left: 50px;
  width: 100%;
`;
const Wrapper = styled.div`
  min-height: 1103px;
  @media (max-width: 450px) {
    min-height: 326px;
  }
`;
const Card = styled.div`
  position: relative;
  top: -220px;
  padding: 1.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.5);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;
const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px !important;
  padding-left: 120px !important;
`;

const H2 = styled.h2`
  font-size: 2rem;
  line-height: 120%;
  color: rgb(1, 86, 34);
  font-weight: 600;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    padding: 0 0px;
  }
  @media (max-width: 576px) {
    padding: 0 0px;
  }
`;
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 180px;
  height: 40px;
  background: none;
  border: 3px solid #284a41;
  color: #284a41;
  font-weight: bold;
  transition: 0.3s;
  margin-top: 5px;
  font-size: 1.2rem;
  :hover {
    background: #284a41;
    color: #fff;
    text-decoration: none;
  }
`;
