import React, { useEffect, useState } from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import ConfirmSubscription from "./ConfirmSubscription";
import { getApprove } from "../../services/api/index";

const ConfirmSubscriptionContainer = (props) => {
  const search = props.location.search;
  const [e, setE] = useState("")
  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get("token");
    getApprove(token).then((res)=>{
      setE("OK");     
    }).catch(
      (error) => {
        if(error.response && error.response.data.message) {
          if(error.response.data.message === "Subscribtion was already approved.") {
            setE("ERRORCONFIRMED");
          }
          if(error.response.data.message === "Token has expired.") {
            setE("ERRORTIMEOUT");
          }
        }
      }
    );
  }, [search]);
  return (
    <>
      <ConfirmSubscription answer={e}/>
      <CarlsbergPoland />
    </>
  );
};

export default ConfirmSubscriptionContainer;
