import React from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import CancelSubscriptionECheck from "./CancelSubscriptionECheck";
const CancelSubscriptionECheckContainer = (props) => {
  const query = new URLSearchParams(props.location.search);
  const token = query.get("token");

  return (
    <>
      <CancelSubscriptionECheck token={token} />
      <CarlsbergPoland />
    </>
  );
};

export default CancelSubscriptionECheckContainer;
