import React from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import EmailExist from "./EmailExist";
const EmailExistContainer = () => {
  return (
    <>
      <EmailExist />
      <CarlsbergPoland />
    </>
  );
};

export default EmailExistContainer;
