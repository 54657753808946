import React from "react";
//import CarlsbergPoland from "../features/carlsbergPoland/CarlsbergPoland";
import NewsletterContainer from "../features/newsletter/newsletter.container";
const Homepage = () => {
  return (
    <>
      <NewsletterContainer />
      {/* <CarlsbergPoland /> */}
    </>
  );
};

export default Homepage;
