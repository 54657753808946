export const i1 = require('./1.png');
export const i2 = require('./2.png');
export const i3 = require('./3.png');
export const i4 = require('./4.png');
export const i5 = require('./5.png');
export const i6 = require('./6.png');
export const i7 = require('./7.png');
export const i8 = require('./8.png');
export const i9 = require('./9.png');
export const i10 = require('./10.png');
export const i11 = require('./11.png');
export const i12 = require('./12.png');
export const i13 = require('./13.png');
export const i14 = require('./14.png');
export const i15 = require('./15.png');
export const i16 = require('./16.png');
export const i17 = require('./17.png');
export const i18 = require('./18.png');
export const i19 = require('./19.png');
export const i20 = require('./20.png');
export const i21 = require('./21.png');
export const i22 = require('./22.png');
export const i23 = require('./23.png');
export const i24 = require('./24.png');
export const i25 = require('./25.png');
export const i26 = require('./26.png');
export const i27 = require('./27.png');
export const i28 = require('./28.png');
export const i29 = require('./29.png');
export const i30 = require('./30.png');
export const i31 = require('./31.png');
export const i32 = require('./32.png');
export const i33 = require('./33.png');
export const i34 = require('./34.png');
export const i35 = require('./35.png');
export const i36 = require('./36.png');
export const i37 = require('./37.png');
export const i38 = require('./38.png');
export const i39 = require('./39.png');
export const i40 = require('./40.png');
export const i41 = require('./41.png');
export const i42 = require('./42.png');
export const i43 = require('./43.png');

export const i44 = require('./44.png');
export const i45 = require('./45.png');
export const i46 = require('./46.png');
export const i47 = require('./47.png');

export const beerList = [
    /* i1, i2, */ i3, i4, i5, i6, i7, i8, i9, i10, i11, i12, i13, i14, i15, i16, i17, i18, i19, i20,
    i21, i22, /* i23, */ i24, i25, i26, i27, i28, i29, i30, i31, i32, i33, i34, i35, i36, i37, i38,
    i39, i40, /* i41, */ i42, i43, i44, i45, i46, i47
]