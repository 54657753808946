import React, { useEffect } from "react";
import styled from "styled-components";
import "./bottles.css";
import { beerList } from "../../images/beers/index";
import { gbListTemp } from "../../images/index";

const CarlsbergPoland = () => {
  useEffect(() => {
    setGreenStripes();
  }, []);

  // random bottles
  let bottles = beerList;
  let random = bottles.sort(() => 0.5 - Math.random()).slice(0, 6);
  let bottleCols = [];
  let colIterator = 1;
  for (var i = 0; i < random.length; i++) {
    let alt = "bottle" + (i + 1);
    let bottleCol = "col-sm-2 bottle-col-" + colIterator;
    colIterator += 1;
    bottleCols.push(
      <div className={bottleCol} key={"b" + i}>
        <img src={random[i]} alt={alt} className="bottle-img"></img>
      </div>
    );
    if (i === 2) {
      colIterator = 1;
    }
  }

  //fix site background loading
  const setGreenStripesBg = () => {
    let randomColor = localStorage.getItem("randomColor") || undefined;
    if (randomColor === undefined) {
      randomColor = 1;
    }
    let stripes = gbListTemp;
    let bg = document.getElementById("stripes");
    if (bg) {
      bg.style.backgroundImage = `url(${stripes[randomColor]})`;
    }
  };
  const setGreenStripes = () => {
    setTimeout(() => {
      setGreenStripesBg();
    }, 1000);
    setTimeout(() => {
      setGreenStripesBg();
    }, 1500);
    setTimeout(() => {
      setGreenStripesBg();
    }, 3000);
  };

  return (
    <Wrapper id="stripes">
      <div className="row">{bottleCols}</div>
    </Wrapper>
  );
};

export default CarlsbergPoland;

const Wrapper = styled.div`
  background: #d7e0df;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgb(40, 74, 65);
  background-size: contain;
  background-color: white;
  background-repeat: round;
`;
