import React from "react";
import styled from "styled-components";
import { bgListTemp } from "../../images/index";

const CanceledSubscription = () => {
  // random background
  const rollBg = () => {
    let backs = bgListTemp;
    let idx = Math.floor(Math.random() * backs.length);
    localStorage.setItem("randomColor", idx);
    return backs[idx];
  };
  return (
    <Jumbotron>
      <Wrapper
        className="row no-gutters"
        style={{ backgroundImage: `url(${rollBg()})` }}
      >
        <Column className="col-md-6 col-xs-5 overflow-hidden position-relative "></Column>
        <RightColumn className="col-md-5 col-xs-5 offset-lg-1">
          <TextWrapper>
            <H2>
              Subskrypcja dla e-mail{" "}
              <P>{localStorage.getItem("email") || ""}</P> została anulowana.
            </H2>
          </TextWrapper>
        </RightColumn>
      </Wrapper>
    </Jumbotron>
  );
};

export default CanceledSubscription;

const Jumbotron = styled.div`
    background: #e8f3e0;
    min-height: 646px
    width: 100%;
`;

const Wrapper = styled.div`
  min-height: 1103px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 40px;
  position: relative;
  taxt-align: center;
  width: 600px;
  top: -220px;
`;
const P = styled.p`
  color: #0079ff;
  font-weight: 400;
`;
const Column = styled.div`
  display: flex;
  align-items: center;
`;
const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 45px !important;
`;
const H2 = styled.h2`
  font-size: 2rem;
  line-height: 130%;
  color: rgb(1, 86, 34);
  font-weight: 600;
  text-align: center;
  font-family: "ThemeFont-Tertiary", sans-serif;
  @media (max-width: 1200px) {
    font-size: 3.6rem;
  }
  @media (max-width: 992px) {
    font-size: 3.3rem;
  }
  @media (max-width: 768px) {
    padding: 0 50px;
  }
  @media (max-width: 576px) {
    font-size: 2.8rem;
    padding: 0 10px;
  }
`;
