import React, { useEffect } from "react";
import styled from "styled-components";
import { bgListTemp } from "../../images/index";

const ConfirmSubscription = (props) => {
  useEffect(() => {
    rollBg();
  }, []);
  // random background
  const rollBg = () => {
    let backs = bgListTemp;
    let idx = Math.floor(Math.random() * backs.length);
    localStorage.setItem("randomColor", idx);
    let wrapper = document.getElementById("wrapper");
    wrapper.style.backgroundImage = `url(${backs[idx]})`;
    return backs[idx];
  };
  return (
    <Jumbotron>
      <Wrapper className="row no-gutters" id="wrapper">
        <Column className="col-md-5 col-xs-10 overflow-hidden position-relative "></Column>
        <RightColumn className="col-md-6 col-xs-10 offset-lg-1">
          {props.answer === "OK" ? (
            <TextWrapper>
              <Paragraph className="brand-slider__description">
                Dziękujemy za zapisanie do Newslettera!
              </Paragraph>
            </TextWrapper>
          ) : props.answer === "ERRORCONFIRMED" ? (
            <TextWrapper>
              <Paragraph24 className="brand-slider__description">
                Czynność, którą próbujesz ponownie potwierdzić została już
                potwierdzona.
              </Paragraph24>
            </TextWrapper>
          ) : props.answer === "ERRORTIMEOUT" ? (
            <TextWrapper>
              <Paragraph24 className="brand-slider__description">
                Upłynnął termin wykonania czynności, którą próbujesz
                potwierdzić.
              </Paragraph24>
            </TextWrapper>
          ) : null}
        </RightColumn>
      </Wrapper>
    </Jumbotron>
  );
};

export default ConfirmSubscription;

const Jumbotron = styled.div`
    background: #e8f3e0;
    min-height: 646px
    width: 100%
    @media (max-width: 450px) {
      min-height: 326px;
    }
`;

const Wrapper = styled.div`
  min-height: 1103px;
  @media (max-width: 450px) {
    min-height: 326px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: -220px;
  text-align: center !important;
  max-width: 580px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px 40px;
  border-radius: 3px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.5);
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;
const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 45px !important;
`;

const Paragraph = styled.p`
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 150%;
  color: #284a41;
`;
const Paragraph24 = styled.p`
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 150%;
  color: #284a41;
`;
