import React from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import CancelSubscription from "./CancelSubscription";
const CancelSubscriptionContainer = (props) => {
  const query = new URLSearchParams(props.location.search);
  const token = query.get("token");

  return (
    <>
      <CancelSubscription token={token} />
      <CarlsbergPoland />
    </>
  );
};

export default CancelSubscriptionContainer;
