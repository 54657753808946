import React from "react";
import styled from "styled-components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  postNewsletter,
  getStates,
  getNets,
  getPage,
} from "../../services/api/index";
import _ from "lodash";
import "./newsletter.css";
//import { ReCaptcha } from 'react-recaptcha-v3'
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { captchaKey } from "../../services/api/host";

const customSearchStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 3,
    textAlign: "left",
    paddingLeft: 5,
  }),
  control: (provided) => ({
    ...provided,
    height: 30,
    minHeight: 30,
    borderRadius: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    position: "relative",
    top: -3,
    padding: "2px 4px",
  }),
};
var net = "";
var region = "";
var all = false;
var phoneRed = false;
class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxs: [],
      captchaVerified: false,
      captchaAlert: false,
      states: [],
      nets: [],
      loading: false,
    };
    this.recaptcharef = React.createRef();
  }
  onStateChange(inputValue) {
    region = inputValue.label;
  }
  onNetChange(inputValue) {
    net = inputValue.label;
  }
  confirmPhoneNumber(no) {
    var reg = new RegExp("^[0-9]+$");
    let confirm = !(reg.test(no) === true && no.length === 9);
    return !confirm;
  }
  confirmInput1(phoneNumber, touched, phoneRed) {
    let confirmed = false;
    let regex = this.confirmPhoneNumber(phoneNumber);
    if (touched === true && phoneRed === true && regex === false) {
      confirmed = false;
    }
    if (touched === true && phoneRed === true && regex === true) {
      confirmed = true;
    }
    if (touched === true && phoneRed === false) {
      confirmed = true;
    }
    if (touched !== true) confirmed = true;
    return confirmed;
  }
  confirmInput2(phoneNumber, secondNumber, touched, phoneRed) {
    let confirmed = false;
    let confirmNumber = phoneNumber === secondNumber;
    let regex = this.confirmPhoneNumber(phoneNumber);
    if (touched === true && phoneRed === true && confirmNumber === false) {
      confirmed = false;
    }
    if (
      touched === true &&
      phoneRed === true &&
      confirmNumber === true &&
      regex === true
    ) {
      confirmed = true;
    }
    if (
      touched === true &&
      phoneRed === true &&
      confirmNumber === true &&
      regex === false
    ) {
      confirmed = false;
    }
    if (touched === true && phoneRed === false) {
      confirmed = true;
    }
    if (touched !== true) confirmed = true;
    return confirmed;
  }
  componentDidMount() {
      getStates().then((res) => {
        let respStates = [];
        res.data.data.objects.forEach((state) => {
          respStates.push({ value: state.SLO_NAZWA, label: state.SLO_NAZWA });
        });
        this.setState({ states: respStates });
      });
      getNets().then((res) => {
        let respNets = [];
        res.data.data.objects.forEach((net) => {
          respNets.push({ value: net.SLO_NAZWA, label: net.SLO_NAZWA });
        });
        this.setState({ nets: respNets });
      });
  }
  handleVerificationSuccess = (token) => {
    if (token) {
      this.setState({
        captchaAlert: false,
        captchaVerified: true,
      });
    }
  };
  alertConfirmRemove = (id) => {
    let alert = document.getElementById(id);
    alert.style.visibility = "hidden";
  };
  alertConfirmShow = (id) => {
    let alert = document.getElementById(id);
    alert.style.visibility = "visible";
  };
  handleClick = (e) => {
    if (e !== undefined && e.target.id === "defaultCheck1") {
      // all check clicked
      var array = document
        .getElementsByClassName("hehe")[0]
        .getElementsByClassName("form-check-input");
      for (let i = 1; i < array.length; i++) {
        if (array[0].checked && !array[i].checked && i !== 5) {
          array[i].checked = true;
        }
        if (!array[0].checked && array[i].checked && i !== 5) {
          array[i].click();
        }
      }
      this.setState({ checkboxs: array });
      if (
        array[2].checked === true ||
        array[3].checked === true ||
        array[4].checked === true
      ) {
        phoneRed = true;
      } else {
        phoneRed = false;
      }
    } else {
      //other is clicked
      if (document.getElementsByClassName("hehe").length > 0) {
        array = document
          .getElementsByClassName("hehe")[0]
          .getElementsByClassName("form-check-input");
        if (
          array[1].checked === false ||
          array[2].checked === false ||
          array[3].checked === false ||
          array[4].checked === false
        ) {
          if (array[0].checked === true) {
            let check1 = array[1].checked;
            let check2 = array[2].checked;
            let check3 = array[3].checked;
            let check4 = array[4].checked;
            array[0].click();
            check1 && array[1].click();
            check2 && array[2].click();
            check3 && array[3].click();
            check4 && array[4].click();
          }
        }
        if (
          array[2].checked === true ||
          array[3].checked === true ||
          array[4].checked === true
        ) {
          phoneRed = true;
        } else {
          phoneRed = false;
        }
      }
    }
    if (e !== undefined) {
      var array = document
        .getElementsByClassName("hehe")[0]
        .getElementsByClassName("form-check-input");
      all = array[0].checked;
    }
  };
  render() {
    const phoneRegExp = /^\d{9}$/;
    return (
      <Wrapper className="" id="icons">
        <Card className="card">
          <div className="card-body">
            <H2> Zapisz się do Newslettera </H2>
            <H4>I bądź na bieżąco z ofertą Carlsberg Polska</H4>
            <Formik
              initialValues={{
                agreementToSendEmail: true,
                companyName: "",
                agreementToSendSms: false,
                agreementToPhoneContact: false,
                agreementToAutomaticContact: false,
                postCode: "",
                city: "",
                street: "",
                houseNumber: "",
                phoneNumber: "",
                confirmPhoneNumber: "",
                email: "",
                confirmEmail: "",
                companyCategory: [],
              }}
              validationSchema={Yup.object().shape({
                agreementToSendEmail: Yup.boolean(),
                agreementToSendSms: Yup.boolean(),
                agreementToPhoneContact: Yup.boolean(),
                agreementToAutomaticContact: Yup.boolean(),
                agreementClause: Yup.boolean(),
                companyCategory: Yup.string().required(
                  "Proszę wybrać kategorię firmy"
                ),
                phoneNumber: Yup.string()
                  .when("agreementToAll", {
                    is: true,
                    then: Yup.string().required(" ").matches(phoneRegExp, " "),
                    otherwise: Yup.string().matches(phoneRegExp, " "),
                  })
                  .when("agreementToSendSms", {
                    is: true,
                    then: Yup.string().required(" ").matches(phoneRegExp, " "),
                    otherwise: Yup.string().matches(phoneRegExp, " "),
                  })
                  .when("agreementToPhoneContact", {
                    is: true,
                    then: Yup.string().required(" ").matches(phoneRegExp, " "),
                    otherwise: Yup.string().matches(phoneRegExp, " "),
                  })
                  .when("agreementToAutomaticContact", {
                    is: true,
                    then: Yup.string().required(" ").matches(phoneRegExp, " "),
                    otherwise: Yup.string().matches(phoneRegExp, " "),
                  }),
                confirmPhoneNumber: Yup.string()
                  .when("agreementToAll", {
                    is: true,
                    then: Yup.string().required(" ").matches(phoneRegExp, " "),
                    otherwise: Yup.string().matches(phoneRegExp, " "),
                  })
                  .when("agreementToSendSms", {
                    is: true,
                    then: Yup.string().required(" ").matches(phoneRegExp, " "),
                    otherwise: Yup.string().matches(phoneRegExp, " "),
                  })
                  .when("agreementToPhoneContact", {
                    is: true,
                    then: Yup.string().required(" ").matches(phoneRegExp, " "),
                    otherwise: Yup.string().matches(phoneRegExp, " "),
                  })
                  .when("agreementToAutomaticContact", {
                    is: true,
                    then: Yup.string().required(" ").matches(phoneRegExp, " "),
                    otherwise: Yup.string().matches(phoneRegExp, " "),
                  })
                  .matches(phoneRegExp, " ")
                  .oneOf([Yup.ref("phoneNumber"), null], " "),
                email: Yup.string().email(" ").required(" "),
                confirmEmail: Yup.string()
                  .oneOf([Yup.ref("email"), null], " ")
                  .required(" "),
              })}
              onSubmit={(fields, e) => {
                if (this.state.captchaVerified === true) {
                  let userData = _.omit(
                    fields,
                    "confirmEmail",
                    "confirmPhoneNumber",
                    "companyCategory"
                  );
                  _.extend(userData, {
                    state: region,
                    net: net,
                    all: all,
                  });
                  let categories = [];
                  fields.companyCategory.forEach((company) => {
                    if (company === "Sklep") {
                      categories.push({ id: 1 });
                    }
                    if (company === "Horeca") {
                      categories.push({ id: 2 });
                    }
                    if (company === "Hurtownik") {
                      categories.push({ id: 3 });
                    }
                  });
                  _.extend(userData, {
                    newsletterId: 1,
                    categories: categories,
                    nip: "",
                    firstName: "",
                    lastName: "",
                  });
                  this.setState({ loading: true });
                  postNewsletter(userData)
                    .then((response) => {
                      window.location.href = "/confirmEmail";
                    })
                    .catch((error) => {
                      if (
                        error.response &&
                        error.response.data.message.includes(
                          "Email already exists"
                        )
                      ) {
                        window.location.href = "/emailExisting";
                      } else {
                        window.location.href = "/subscriptionFailed";
                      }
                    });
                } else {
                  this.setState({ captchaAlert: true });
                }
              }}
            >
              {({ values, handleChange, errors, status, touched }) => (
                <Form>
                  {this.handleClick()}
                  <div className="text-right">
                    <div className="form-group row">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="email"
                      >
                        E-mail <Required>*</Required>
                      </label>
                      <Field
                        name="email"
                        type="text"
                        className={
                          "col-sm-6 form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback text-center"
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="confirmPassword"
                      >
                        <Italic>Powtórz E-mail </Italic>
                        <Required>*</Required>
                      </label>
                      <Field
                        name="confirmEmail"
                        type="email"
                        className={
                          "col-sm-6 form-control" +
                          (errors.confirmEmail && touched.confirmEmail
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="confirmEmail"
                        component="div"
                        className="invalid-feedback text-center"
                      />
                    </div>
                    <Spacer></Spacer>
                    <div className="form-group row group-relative">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="phoneNumber"
                      >
                        Telefon komórkowy
                      </label>
                      <Field
                        name="phoneNumber"
                        type="text"
                        className={
                          "col-sm-6 form-control" +
                          (this.confirmInput1(
                            values.phoneNumber,
                            touched.phoneNumber,
                            phoneRed
                          ) === true
                            ? ""
                            : " is-invalid")
                        }
                      />
                      <span
                        className="custom-alert-box unselectable alert-top"
                        id="phoneAlert"
                        onClick={() => this.alertConfirmRemove("phoneAlert")}
                        style={
                          this.confirmInput1(
                            values.phoneNumber,
                            touched.phoneNumber,
                            phoneRed
                          ) === true
                            ? { visibility: "hidden" }
                            : values.phoneNumber === ""
                            ? { visibility: "visible", top: "-60px" }
                            : { visibility: "visible" }
                        }
                      >
                        <span className="custom-alert-arrow"></span>
                        <span className="custom-alert-icon">!</span>
                        {values.phoneNumber === ""
                          ? "Nie podano numeru, ale zaznaczono zgody na otrzymywanie informacji sms, telefonicznej i/lub za pomocą automatycznych systemów wywołujących"
                          : "Podano numer w innym formacie niż 9 znaków cyfrowych"}
                      </span>
                      {this.confirmInput1(
                        values.phoneNumber,
                        touched.phoneNumber,
                        phoneRed
                      ) === true
                        ? null
                        : this.alertConfirmShow("phoneAlert")}
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="invalid-feedback text-center"
                      />
                    </div>
                    <div className="form-group row group-relative">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="confirmPhoneNumber"
                      >
                        <Italic>Powtórz telefon komórkowy</Italic>
                      </label>
                      <Field
                        name="confirmPhoneNumber"
                        type="text"
                        className={
                          "col-sm-6 form-control" +
                          (this.confirmInput2(
                            values.phoneNumber,
                            values.confirmPhoneNumber,
                            touched.confirmPhoneNumber,
                            phoneRed
                          ) === true
                            ? ""
                            : " is-invalid")
                        }
                      />
                      <span
                        className="custom-alert-box unselectable alert-bottom"
                        id="confirmAlert"
                        style={
                          this.confirmInput2(
                            values.phoneNumber,
                            values.confirmPhoneNumber,
                            touched.confirmPhoneNumber,
                            phoneRed
                          ) === true
                            ? { visibility: "hidden" }
                            : this.confirmInput1(
                                values.phoneNumber,
                                touched.phoneNumber,
                                phoneRed
                              ) === false
                            ? { visibility: "hidden" }
                            : { visibility: "visible" }
                        }
                        onClick={() => this.alertConfirmRemove("confirmAlert")}
                      >
                        <span className="custom-alert-arrow"></span>
                        <span className="custom-alert-icon">!</span>
                        Podany numer jest inny niż w pierwszym polu.
                      </span>
                      {this.confirmInput2(
                        values.phoneNumber,
                        values.confirmPhoneNumber,
                        touched.confirmPhoneNumber,
                        phoneRed
                      ) === true
                        ? null
                        : this.confirmInput1(
                            values.phoneNumber,
                            touched.phoneNumber,
                            phoneRed
                          ) === false
                        ? null
                        : this.alertConfirmShow("confirmAlert")}
                      <ErrorMessage
                        name="confirmPhoneNumber"
                        component="div"
                        className="invalid-feedback text-center"
                      />
                    </div>
                    <Spacer></Spacer>
                    <div className="form-group row">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="companyName"
                      >
                        Nazwa firmy
                      </label>
                      <Field
                        name="companyName"
                        type="text"
                        className={
                          "col-sm-6 form-control" +
                          (errors.companyName && touched.companyName
                            ? " is-invalid"
                            : "")
                        }
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        className={
                          "col-sm-5 col-form-label newsletter-label" +
                          (errors.companyCategory && touched.companyCategory
                            ? " is-invalid"
                            : "")
                        }
                        htmlFor="companyCategory"
                        required
                      >
                        Kategoria firmy <Required>*</Required>
                      </label>
                      <div className="d-flex align-items-center mx-3">
                        <Field
                          name="companyCategory"
                          type="checkbox"
                          value="Sklep"
                          id="skl"
                        />
                        <LabelCheck
                          className="mr-4 newsletter-radio unselectable"
                          htmlFor="skl"
                        >
                          Sklep
                        </LabelCheck>
                        <Field
                          name="companyCategory"
                          type="checkbox"
                          value="Horeca"
                          id="horec"
                        />
                        <LabelCheck
                          className="mr-4 newsletter-radio unselectable"
                          htmlFor="horec"
                        >
                          Horeca
                        </LabelCheck>
                        <Field
                          name="companyCategory"
                          type="checkbox"
                          value="Hurtownik"
                          id="hurt"
                        />
                        <LabelCheck
                          className="newsletter-radio unselectable"
                          htmlFor="hurt"
                        >
                          Hurtownik
                        </LabelCheck>
                      </div>

                      <ErrorMessage
                        name="companyCategory"
                        component="div"
                        className="invalid-feedback text-center"
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="netName"
                      >
                        Nazwa sieci
                      </label>
                      <Select
                        options={this.state.nets}
                        id="netName"
                        className="col-sm-6 search-select mx-3"
                        styles={customSearchStyles}
                        onChange={this.onNetChange}
                        placeholder="Wybierz lub wyszukaj..."
                      />
                    </div>
                    <AddressHeader>
                      Adres głównego miejsca wykonywania działalności
                      gospodarczej
                    </AddressHeader>
                    <div className="form-group row">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="region"
                      >
                        Województwo
                      </label>
                      <Select
                        options={this.state.states}
                        id="region"
                        className="col-sm-6 search-select mx-3"
                        styles={customSearchStyles}
                        onChange={this.onStateChange}
                        placeholder="Wybierz lub wyszukaj..."
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="postCode"
                      >
                        Kod pocztowy
                      </label>
                      <Field
                        name="postCode"
                        type="text"
                        className={
                          "col-sm-6 form-control" +
                          (errors.postCode && touched.postCode
                            ? " is-invalid"
                            : "")
                        }
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="city"
                      >
                        Miejscowość
                      </label>
                      <Field
                        name="city"
                        type="text"
                        className={
                          "col-sm-6 form-control" +
                          (errors.city && touched.city ? " is-invalid" : "")
                        }
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-5 col-form-label newsletter-label"
                        htmlFor="street"
                      >
                        Ulica
                      </label>
                      <Field
                        name="street"
                        type="text"
                        className={
                          "col-sm-6 form-control" +
                          (errors.street && touched.street ? " is-invalid" : "")
                        }
                      />
                    </div>
                    <div>
                      <div className=" form-group row">
                        <label
                          className="col-sm-5 col-form-label newsletter-label"
                          htmlFor="houseNumber"
                        >
                          Numer
                        </label>
                        <Field
                          name="houseNumber"
                          type="text"
                          className={
                            "col-sm-6 form-control" +
                            (errors.houseNumber && touched.houseNumber
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <Spacer></Spacer>
                  <div className="agreement-container">
                    <div className="form-group row ml-2 mt-1">
                      <div className="form-check">
                        <Input18
                          className="mt-2"
                          type="checkbox"
                          name="agreementTo18"
                          value=""
                          required
                          onChange={handleChange}
                          id="defaultCheck0"
                        />
                        <Label18
                          className="form-check-label"
                          htmlFor="defaultCheck0"
                        >
                          Oświadczam, że jestem osobą pełnoletnią i mam
                          ukończone 18 lat.<Required>*</Required>
                        </Label18>
                      </div>
                    </div>
                    <div className="form-group row ml-2 mt-1">
                      <div className="form-check">
                        <input
                          className="form-check-input mt-2"
                          type="checkbox"
                          name="agreementToAll"
                          value=""
                          onChange={handleChange}
                          onClick={this.handleClick}
                          id="defaultCheck1"
                        />
                        <label
                          className="form-check-label acceptLabel"
                          htmlFor="defaultCheck1"
                        >
                          Akceptuję i zaznaczam wszystkie poniższe zgody.
                        </label>
                      </div>
                    </div>
                    <div className="hehe">
                      <div className="form-group row ml-2 mr-2 mb-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            required
                            onClick={this.handleClick}
                            id="check1"
                          />
                          <label className="form-check-label" htmlFor="check1">
                            Wyrażam zgodę na otrzymywanie informacji handlowych
                            od Carlsberg Polska sp. z o.o. za pośrednictwem
                            e-mail.{" "}
                            <a
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                              className="collapsed roll"
                            ></a>
                            <Required>*</Required>
                          </label>
                        </div>
                      </div>
                      <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                          <Justify>
                            Wyrażam zgodę na otrzymywanie informacji handlowych
                            od Carlsberg Polska sp. z o.o., ul. Krakowiaków 34,
                            02-255 Warszawa, informacji handlowych dotyczących
                            produktów lub usług oferowanych przez Carlsberg
                            Polska sp. z o.o. lub jej zaufanych partnerów
                            handlowych za pośrednictwem e-mail.
                          </Justify>
                        </div>

                        <ErrorMessage
                          name="chceck1"
                          component="label"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group row ml-2 mr-2 mb-1">
                        <div className="d-flex form-check ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            name="agreementToSendSms"
                            onChange={handleChange}
                            id="check2"
                          />
                          <label className="form-check-label" htmlFor="check2">
                            Wyrażam zgodę na otrzymywanie informacji handlowych
                            od Carlsberg Polska sp. z o.o. za pośrednictwem sms.{" "}
                            <a
                              data-toggle="collapse"
                              href="#collapse2"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                              className="collapsed roll"
                            ></a>
                          </label>
                        </div>
                      </div>
                      <div className="collapse" id="collapse2">
                        <div className="card card-body">
                          <Justify>
                            Wyrażam zgodę na otrzymywanie od Carlsberg Polska
                            sp. z o.o., ul. Krakowiaków 34, 02-255 Warszawa,
                            informacji handlowych dotyczących produktów lub
                            usług oferowanych przez Carlsberg Polska sp. z o.o.
                            lub jej zaufanych partnerów handlowych za
                            pośrednictwem SMS.
                          </Justify>
                        </div>
                      </div>
                      <div className="form-group row ml-2 mb-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            name="agreementToPhoneContact"
                            onChange={handleChange}
                            id="check3"
                          />
                          <label className="form-check-label" htmlFor="check3">
                            Wyrażam zgodę na otrzymywanie informacji handlowych
                            od Carlsberg Polska sp. z o.o. telefonicznie.{" "}
                            <a
                              data-toggle="collapse"
                              href="#collapse3"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                              className="collapsed roll"
                            ></a>
                          </label>
                        </div>
                      </div>
                      <div className="collapse" id="collapse3">
                        <div className="card card-body">
                          <Justify>
                            Wyrażam zgodę na otrzymywanie od Carlsberg Polska
                            sp. z o.o., ul. Krakowiaków 34, 02-255 Warszawa,
                            informacji handlowych dotyczących produktów lub
                            usług oferowanych przez Carlsberg Polska sp. z o.o.
                            lub jej zaufanych partnerów handlowych
                            telefonicznie.
                          </Justify>
                        </div>
                      </div>
                      <div className="form-group row ml-2 mb-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            name="agreementToAutomaticContact"
                            onChange={handleChange}
                            id="check4"
                          />
                          <label className="form-check-label" htmlFor="check4">
                            Wyrażam zgodę na otrzymywanie informacji handlowych
                            od Carlsberg Polska sp. z o.o. za pośrednictwem
                            automatycznych systemów wywołujących.{" "}
                            <a
                              data-toggle="collapse"
                              href="#collapse4"
                              onChange={handleChange}
                              aria-expanded="false"
                              aria-controls="collapseExample"
                              className="collapsed roll"
                            ></a>
                          </label>
                        </div>
                      </div>
                      <div className="collapse" id="collapse4">
                        <div className="card card-body">
                          <Justify>
                            Wyrażam zgodę na otrzymywanie od Carlsberg Polska
                            sp. z o.o., ul. Krakowiaków 34, 02-255 Warszawa,
                            informacji handlowych dotyczących produktów lub
                            usług oferowanych przez Carlsberg Polska sp. z o.o.
                            lub jej zaufanych partnerów handlowych za
                            pośrednictwem automatycznych systemów wywołujących.
                          </Justify>
                        </div>
                      </div>
                      <div className="form-group row ml-2 mb-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            required
                            name="agreementClause"
                            onChange={handleChange}
                            id="check5"
                          />
                          <label className="form-check-label" htmlFor="check5">
                            Zapoznałem/łam się z klauzulą informacyjną Carlsberg
                            Polska Sp z o.o.{" "}
                            <a
                              data-toggle="collapse"
                              href="#collapse5"
                              onChange={handleChange}
                              aria-expanded="false"
                              aria-controls="collapseExample"
                              className="collapsed roll"
                            ></a>
                            <Required>*</Required>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="collapse5">
                      <div className="card card-body">
                        <Justify>
                          Administratorem Państwa danych osobowych jest spółka
                          Carlsberg Polska sp. z o.o., ul. Krakowiaków 34,
                          02-255 Warszawa. Państwa dane osobowe będą
                          przetwarzane w celu marketingu bezpośredniego
                          produktów lub usług oferowanych przez Carlsberg Polska
                          sp. z o.o. lub jej zaufanych partnerów handlowych.
                          Podstawą prawną przetwarzania Państwa danych osobowych
                          jest prawnie uzasadniony interes administratora (art.
                          6 ust. 1 lit. f) RODO) polegający na prowadzeniu
                          marketingu bezpośredniego przez Carlsberg Polska sp. z
                          o.o. Przyjmuję do wiadomości, że wyrażenie zgody na
                          otrzymywanie informacji handlowych za pośrednictwem
                          wybranych środków komunikacji elektronicznej jest
                          dobrowolne. W przypadku nie wyrażenia powyższych zgód
                          nie będą jednak mogli Państwo otrzymywać od Carlsberg
                          Polska sp. z o.o. aktualnych ofert promocyjnych.
                          Powyższą zgodę mogą Państwo w dowolnym momencie
                          wycofać, co nie wpływa jednak na zgodność z prawem
                          przetwarzania dokonanego przed jej wycofaniem.
                          Przysługuje Państwu także prawo do wniesienia
                          sprzeciwu wobec przetwarzania przez Carlsberg Polska
                          sp. z o.o. danych osobowych w celu marketingu
                          bezpośredniego w oparciu o prawnie uzasadniony interes
                          Carlsberg Polska sp. z o.o. (art. 6 ust. 1 lit. f)
                          RODO). Więcej informacji o przetwarzaniu danych
                          osobowych i przysługujących prawach znajdą Państwo w
                          polityce prywatności znajdującej się na stronie
                          internetowej{" "}
                          <a href="https://www.carlsbergpolska.pl">
                            www.carlsbergpolska.pl
                          </a>
                          . We wszystkich sprawach związanych z przetwarzaniem
                          danych osobowych mogą Państwo kontaktować pod adresem{" "}
                          <a href="mailto://daneosobowe@carlsberg.pl">
                            daneosobowe@carlsberg.pl
                          </a>
                          .
                        </Justify>
                      </div>
                    </div>
                  </div>
                  <CenterDiv>
                    <FieldsRequired>
                      * Pola oznaczone gwiazdką są obowiązkowe
                    </FieldsRequired>
                  </CenterDiv>
                  <CaptchaDiv>
                    <ReCAPTCHA
                      sitekey={captchaKey}
                      ref={this.recaptcharef}
                      onChange={this.handleVerificationSuccess}
                      type="image"
                      style={{
                        margin: "auto",
                        overflow: "hidden",
                      }}
                    />
                  </CaptchaDiv>
                  <div className="form-group row justify-content-center">
                    <Button
                      className="col-sm-4 mt-4"
                      type="submit"
                      style={
                        this.state.loading
                          ? {
                              opacity: "0.6",
                              backgroundColor: "transparent",
                              mouseEvents: "none",
                              color: "#284a41",
                              cursor: "default",
                            }
                          : {}
                      }
                    >
                      ZAPISZ SIĘ
                    </Button>
                    <Spinner>
                      <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={this.state.loading}
                        timeout={900000}
                      />
                    </Spinner>
                  </div>

                  <SpacerSmall></SpacerSmall>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </Wrapper>
    );
  }
}
export default Newsletter;
const Wrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 2px;
  padding-left: 10px;
  height: unset;

  @media (max-width: 1430px) {
    margin-right: auto;
    height: fit-content;
  }

  @media (max-width: 675px) {
    padding-left: 0;
  }
`;

const H2 = styled.div`
  font-size: 2.2rem;
  font-weight: bold;
  color: #284a41;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 5px;
  text-align: center;
  @media (max-width: 450px) {
    font-size: 1.6rem;
  }
`;
const Justify = styled.span`
  text-align: justify;
`;
const FieldsRequired = styled.span`
  color: red;
  opacity: 0.95;
  margin-left: 22px;
  position: relative;
  top: -3px;
`;
const CenterDiv = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 11px;
`;
const Input18 = styled.input`
  cursor: pointer;
  position: absolute;
  left: 0px;
`;
const Label18 = styled.label`
  position: relative;
  left: 0px;
  top: 2px;
`;
const CaptchaDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 7px;

  @media (max-width: 370px) {
    max-width: 270px;
  }
`;
const H4 = styled.div`
  padding-left: 5px;
  font-size: 1.2rem;
  color: #284a41;
  margin-top: -12px;
  font-style: oblique;
  margin-bottom: 25px;
  text-align: center;
  @media (max-width: 450px) {
    font-size: 1rem;
  }
`;
const AddressHeader = styled.div`
  width: 100%;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 15px;
  margin-top: 30px;
  font-size: 13px;
  opacity: 1;
  font-weight: bold;
  color: #284a41;
`;
const LabelCheck = styled.label`
  margin-left: 0.2rem;
  position: relative;
  top: 2px;
`;
const Required = styled.span`
  display: inline;
  color: red;
`;
const Italic = styled.div`
  display: inline;
  font-style: oblique;
  font-size: 13px;
  text-align: center;
`;
const Spacer = styled.div`
  display: block;
  height: 20px;
`;
const SpacerSmall = styled.div`
  display: block;
  height: 10px;
`;
const Card = styled.div`
  width: 567px;
  padding: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.95);
  @media (max-width: 675px) {
    width: auto;
  }
`;
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 200px;
  height: 40px;
  background: none;
  border: 3px solid #284a41;
  color: #284a41;
  font-weight: bold;
  transition: 0.3s;
  :hover {
    background: #284a41;
    color: #fff;
    text-decoration: none;
  }
`;
const Spinner = styled.div`
  position: relative;
  width: 0;
  top: 16px;
  left: 10px;
`;
