import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import { unsubscribe, unsubscribeCheckToken } from "../../services/api/index";
import { bgListTemp } from "../../images/index";
import "../../features/newsletter/newsletter.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

var text = "";

const CancelSubscription = (props) => {
  const [bg, setBg] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenChecking, setTokenChecking] = useState(true);
  const [tokenOK, setTokenOK] = useState(false);
  const [unsubscribeAlert, setUnsubscribeAlert] = useState("");

  useEffect(() => {
    setBg(rollBg());
    unsubscribeCheckToken(props.token)
      .then((res) => {
        setTokenChecking(false);
        setTokenOK(true);
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          if (error.response.data.message.includes("not found.")) {
            setTokenChecking(false);
            setUnsubscribeAlert(
              "Czynność, którą próbujesz wykonać została już potwierdzona."
            );
          }
          if (error.response.data.message === "Token has expired.") {
            setTokenChecking(false);
            setUnsubscribeAlert(
              "Upłynął termin wykonania czynności, którą próbujesz potwierdzić."
            );
          }
        }
      });
  }, []);
  // random background
  const rollBg = () => {
    let backs = bgListTemp;
    let idx = Math.floor(Math.random() * backs.length);
    localStorage.setItem("randomColor", idx);
    return backs[idx];
  };
  const radioChange = (e) => {
    let array = document.getElementsByClassName("radios");
    let area = document.getElementsByClassName("area")[0];
    text = e.target.value;
    if (array[3].checked) {
      area.disabled = false;
      text = area.value;
    } else {
      area.disabled = true;
    }
  };
  const decodeToken = () => {
    let email = "";
    if (props.token) {
      let obj = JSON.parse(atob(props.token.split(".")[0]));
      email = obj.email;
    }
    return email;
  };
  const submitHandler = () => {
    if (props.token) {
      setTimeout(() => {
        localStorage.setItem("email", decodeToken());
        setLoading(true);
        unsubscribe(props.token, text)
          .then((response) => {
            window.location.href = "/canceledSubscription";
          })
          .catch((error) => {
            if (error.response && error.response.data.message) {
              if (error.response.data.message.includes("not found.")) {
                window.location.href = "/unsubscribeUserNotFound";
              } else if (error.response.data.message === "Token has expired.") {
                window.location.href = "/unsubscribeTokenExpired";
              } else {
                window.location.href = "/subscriptionFailed";
              }
            } else {
              window.location.href = "/subscriptionFailed";
            }
          });
      }, 0);
    }
  };
  return (
    <Jumbotron>
      <Wrapper
        className="row no-gutters"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <Column className="col-md-6 col-xs-10 overflow-hidden position-relative "></Column>
        <RightColumn
          className="col-md-6 col-xs-8  row justify-content-center"
          style={tokenChecking === true ? { opacity: 0 } : {}}
        >
          <Card className="text-center">
            <div className="card-body">
              {tokenOK === true ? (
                <>
                  <H2 className="mb-4">
                    Zamierzasz usunąć e-mail <Mail>{decodeToken()}</Mail> z
                    Newslettera. Powiedz nam dlaczego?
                  </H2>
                  <Formik
                    initialValues={{
                      content: "",
                    }}
                    onSubmit={(fields, e) => {}}
                  >
                    {({ errors, status, touched }) => (
                      <Form onChange={radioChange}>
                        {" "}
                        <div className="form-group row">
                          <FormDiv>
                            <RadioDiv className="mb-1 mt-3">
                              <Field
                                name="companyCategory"
                                type="radio"
                                value="Dostaję za dużo wiadomości"
                                className="radios"
                                id="dostaje"
                              />
                              <Label
                                className="mr-4 ml-3 newsletter-radio unselectable"
                                htmlFor="dostaje"
                              >
                                {" "}
                                Dostaję za dużo wiadomości
                              </Label>
                            </RadioDiv>
                            <RadioDiv className="mb-1">
                              <Field
                                name="companyCategory"
                                type="radio"
                                value="Oferty mają za mało szczegółów"
                                className="radios"
                                id="zamalo"
                              />
                              <Label
                                className="mr-4 ml-3 newsletter-radio unselectable"
                                htmlFor="zamalo"
                              >
                                {" "}
                                Oferty mają za mało szczegółów
                              </Label>
                            </RadioDiv>
                            <RadioDiv>
                              <Field
                                name="companyCategory"
                                type="radio"
                                value="Już nie prowadzę tej działalności"
                                className="radios"
                                id="nieprowadze"
                              />
                              <Label
                                className="mr-4 ml-3 newsletter-radio unselectable"
                                htmlFor="nieprowadze"
                              >
                                {" "}
                                Już nie prowadzę tej działalności
                              </Label>
                            </RadioDiv>
                            <RadioDiv className="mb-1">
                              <Field
                                name="companyCategory"
                                type="radio"
                                value="Inny"
                                className="radios"
                                id="inne"
                              />
                              <Label
                                className="mr-4 ml-3 newsletter-radio unselectable"
                                htmlFor="inne"
                              >
                                {" "}
                                Inny:
                              </Label>
                              <Field
                                as="textarea"
                                name="content"
                                type="text"
                                disabled
                                className={"form-control area"}
                                style={{
                                  height: "75px",
                                  width: "90%",
                                  fontSize: "14px",
                                  marginTop: "10px",
                                }}
                              />
                            </RadioDiv>
                          </FormDiv>
                        </div>
                        <div className="form-group row justify-content-center">
                          <ButtonContainer>
                            <Button
                              className=""
                              type="submit"
                              onClick={submitHandler}
                              style={
                                loading
                                  ? {
                                      opacity: "0.6",
                                      backgroundColor: "transparent",
                                      mouseEvents: "none",
                                      color: "#284a41",
                                      cursor: "default",
                                    }
                                  : {}
                              }
                            >
                              WYPISZ SIĘ
                            </Button>
                            <Spinner>
                              <Loader
                                type="Oval"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                visible={loading}
                                timeout={900000}
                              />
                            </Spinner>
                          </ButtonContainer>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              ) : (
                <AlertDiv>
                  <Paragraph className="brand-slider__description">
                    {unsubscribeAlert}
                  </Paragraph>
                  <Button
                    href="/"
                    style={{
                      margin: "auto",
                      position: "relative",
                      top: "20px",
                    }}
                  >
                    Wróć na stronę główną
                  </Button>
                </AlertDiv>
              )}
            </div>
          </Card>
        </RightColumn>
      </Wrapper>
    </Jumbotron>
  );
};

export default CancelSubscription;

const Jumbotron = styled.div`
    background: #e8f3e0;
    min-height: 646px
    width: 100%;
   @media(max-width: 450px){
      min-height: 326px;
    }
`;
const AlertDiv = styled.div`
  min-height: 160px;
  padding: 5px;
  text-align: center;
`;
const Label = styled.label`
  font-size: 14px;
  position: relative;
  top: -2px;
`;
const Mail = styled.span`
  color: #0079ff;
  font-weight: 400;
`;
const Required = styled.span`
  display: inline;
  color: red;
  font-weight: 100;
`;
const RadioDiv = styled.div`
  margin-top: 6px;
  margin-bottom: 7px;
`;
const Spinner = styled.div`
  position: absolute;
  top: 26px;
  left: 190px;
`;
const ButtonContainer = styled.div`
  position: relative;
`;
const Alert = styled.div`
  color: red;
  position: relative;
  top: 3px;
`;
const FormDiv = styled.div`
  text-align: left;
  padding-left: 50px;
  width: 100%;
`;
const Wrapper = styled.div`
  min-height: 1103px;
  @media (max-width: 450px) {
    min-height: 326px;
  }
`;
const Card = styled.div`
  position: relative;
  top: -220px;
  padding: 1.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.5);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;
const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px !important;
  padding-left: 60px !important;
`;

const H2 = styled.h2`
  font-size: 2rem;
  line-height: 120%;
  color: rgb(1, 86, 34);
  font-weight: 600;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    padding: 0 0px;
  }
  @media (max-width: 576px) {
    padding: 0 0px;
  }
`;
const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 180px;
  height: 40px;
  background: none;
  border: 3px solid #284a41;
  color: #284a41 !important;
  font-weight: bold;
  transition: 0.3s;
  margin-top: 15px;
  cursor: pointer;
  font-size: 1.2rem;
  :hover {
    background: #284a41;
    color: #fff !important;
    text-decoration: none;
  }
`;
const Paragraph = styled.p`
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 150%;
  color: #284a41;
`;
