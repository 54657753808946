import React, { useState } from "react";
import "./cookiesAcceptation.css";
import Switch from "./Switch";
import "./Switch.css";
import { host } from "../../services/api/host";

const CookiesAcceptation = ({
  forceCookiesSettings,
  setForceCookiesSettings,
}) => {
  const [cookiesData, setCookiesData] = useState({
    ...JSON.parse(localStorage.getItem("cookiesAccepted")),
    neccesaryCookies: true,
    functionalCookies: true,
  });
  const [alertVisible, setAlertVisible] = useState(true);

  const [defaultSaveText, setDefaultSaveText] = useState(
    "Odmawiam opcjonalnych"
  );

  const showDescription = (element, id) => {
    document.getElementById(id).classList.toggle("hidden");
    document.getElementById(id + "-icon").classList.toggle("reversed");

    if (document.getElementById(id).classList.contains("hidden")) {
      document.getElementById(id + "-text").textContent = "Dowiedz się więcej";
    } else {
      document.getElementById(id + "-text").textContent = "Zwiń";
    }
  };

  return (
    <>
      {((JSON.parse(localStorage.getItem("cookiesAccepted"))
        ?.neccesaryCookies !== true &&
        alertVisible) ||
        forceCookiesSettings) && (
        <div className="cookies-container">
          <div className="cookies-content">
            <div className="info-container">
              <h1 className="cookies-settings-title">
                Zarządzanie plikami cookie w celu optymalizacji korzystania ze
                strony
              </h1>
              <div className="text-container">
                <p className="text">
                  Nasza witryna korzysta z plików cookie firmy Carlsberg Sp. z
                  o.o. (Carlsberg Polska) i podmiotów z grupy Carlsberg, aby
                  zapewnić najlepsze wrażenia cyfrowe, personalizować treści i
                  wyświetlać bardziej odpowiednie reklamy dotyczące naszych
                  wspaniałych produktów. Możemy również analizować ruch i
                  udostępniać funkcje do naszych partnerów, a także udostępniać
                  im pliki cookie.
                </p>
                Kliknij "Zezwól na wszystko", jeśli zgadzasz się na używanie
                plików cookie (...)
                <div className="details-container">
                  <div className="details hidden" id="details-1">
                    Kliknij „Zezwól na wszystko”, jeśli zgadzasz się na używanie
                    plików cookie przez Carlsberg Polska, Podmioty Grupy
                    Carlsberg i naszych partnerów. Możesz też wybrać rodzaj
                    plików cookie, z których chętnie korzystasz. Możesz
                    zaktualizować swoje preferencje w dowolnym momencie.
                  </div>
                  <div
                    className="show-more"
                    onClick={(e) => showDescription(e.target, "details-1")}
                  >
                    <i className="icon" id="details-1-icon" />
                    <p id="details-1-text" className="details-text">
                      Dowiedz się więcej
                    </p>
                  </div>
                </div>
                <p className="text">
                  Chcesz dowiedzieć się więcej o tym, jak użyamy plików cookie i
                  szanujemy Twoją prywatność? Sprawdź nasze
                  <a
                    className="text-link"
                    target="_blank"
                    /* href="/cookies-policy" */
                    href={`${host}/Admin/publicFooter/getLinksForRegulations/link/policy_cookies_1`}
                  >
                    Powiadomienia dotyczące plików cookie
                  </a>
                  i
                  <a
                    className="text-link"
                    target="_blank"
                    /* href="/privacy-policy" */
                    href={`${host}/Admin/publicFooter/getLinksForRegulations/link/policy_privacy_1`}
                  >
                    Powiadomienia dotyczące zasad prywatności.
                  </a>
                </p>
                <br />
              </div>
            </div>
            <div className="settings-container">
              <div className="switch-container">
                <div className="switch-with-label">
                  <Switch
                    value={true}
                    color={"lightgrey"}
                    id="neccesaryCookies"
                    name="neccesaryCookies"
                    disabled={true}
                  />
                  <label className="label-cookies" htmlFor="neccesaryCookies">
                    Niezbędne pliki cookie (zawsze włączone)
                  </label>
                </div>

                <div className="details-container">
                  <div className="details hidden" id="details-2">
                    Te pliki cookie są niezbędne aby umożliwić użytkownikowi
                    poruszanie się po stronie i korzystanie z jej funkcji. Bez
                    nich nie jest możliwe zapewnienie użytkownikom usług takich
                    jak: weryfikacja wieku, nawigacja między stronami,
                    korzystanie z koszyka lub usług e-fakturowania.
                  </div>
                  <div
                    className="show-more"
                    onClick={(e) => showDescription(e.target, "details-2")}
                  >
                    <i className="icon" id="details-2-icon" />
                    <p id="details-2-text" className="details-text">
                      Dowiedz się więcej
                    </p>
                  </div>
                </div>
              </div>

              <div className="switch-container">
                <div className="switch-with-label">
                  <Switch
                    value={true}
                    color={"lightgrey"}
                    id="functionalCookies"
                    name="functionalCookies"
                  />
                  <label className="label-cookies" htmlFor="functionalCookies">
                    Funkcjonalne pliki cookie (zawsze włączone)
                  </label>
                </div>

                <div className="details-container">
                  <div className="details hidden" id="details-3">
                    Używamy funkcjonalne pliki cookie w celu zapewnienia
                    użytkownikom określonej funkcjonalności, np. zapamiętania
                    dokonywanych wyborów (takich jak nazwa użytkownika, język
                    lub region, w którym się znajduje użytkownik) lub
                    rozpoznania platformy, z której następuje dostęp do strony
                    oraz w celu zapewnienia ulepszonych i bardziej osobistych
                    funkcji. Pliki te nie są używane do śledzenia aktywności
                    użytkownika na innych przeglądanych stronach.
                  </div>
                  <div
                    className="show-more"
                    onClick={(e) => showDescription(e.target, "details-3")}
                  >
                    <i className="icon" id="details-3-icon" />
                    <p id="details-3-text" className="details-text">
                      Dowiedz się więcej
                    </p>
                  </div>
                </div>
              </div>
              <div className="switch-container">
                <div className="switch-with-label">
                  <Switch
                    value={cookiesData?.analysisCookies ? true : false}
                    color={cookiesData?.analysisCookies ? "#115622" : ""}
                    handleToggle={(value) => {
                      setCookiesData({
                        ...cookiesData,
                        analysisCookies: value,
                      });
                      setDefaultSaveText("Zapisz i kontynuuj");
                    }}
                    id="analysisCookies"
                    name="analysisCookies"
                  />
                  <label className="label-cookies" htmlFor="analysisCookies">
                    Pliki cookie dotyczące wydajności i analityki (opcjonalne)
                  </label>
                </div>

                <div className="details-container">
                  <div className="details hidden" id="details-4">
                    Korzystamy z plików cookie związanych z wydajnością i
                    analitycznych aby badać, w jaki sposób nasi użytkownicy
                    korzystają ze strony i monitorować jej wydajność. Dzięki
                    temu możemy zapewnić jak najlepsze korzystanie ze strony,
                    dostosowując naszą ofertę oraz szybko identyfikując i
                    usuwając wszelkie pojawiające się problemy. Jeżeli
                    użytkownik nie zezwoli na te pliki, nie będziemy w stanie
                    monitorować użytkowania i wydajności strony ani poprawiać
                    jej działania.
                  </div>
                  <div
                    className="show-more"
                    onClick={(e) => showDescription(e.target, "details-4")}
                  >
                    <i className="icon" id="details-4-icon" />
                    <p id="details-4-text" className="details-text">
                      Dowiedz się więcej
                    </p>
                  </div>
                </div>
              </div>

              <div className="buttons">
                <button
                  className="save button"
                  style={{
                    fontSize:
                      defaultSaveText == "Odmawiam opcjonalnych"
                        ? "15px"
                        : null,
                  }}
                  onClick={() => {
                    localStorage.setItem(
                      "cookiesAccepted",
                      JSON.stringify(cookiesData)
                    );
                    setAlertVisible(false);
                    setForceCookiesSettings(false);
                  }}
                >
                  {defaultSaveText}
                </button>
                <button
                  className="accept button"
                  onClick={() => {
                    const data = {
                      neccesaryCookies: true,
                      functionalCookies: true,
                      analysisCookies: true,
                    };
                    localStorage.setItem(
                      "cookiesAccepted",
                      JSON.stringify({ ...cookiesData, analysisCookies: true })
                    );
                    setCookiesData(data);
                    setForceCookiesSettings(false);
                    setAlertVisible(false);
                  }}
                >
                  Akceptuję wszystkie
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesAcceptation;
