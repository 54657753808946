export const bg1 = require("./back-new/back-new-1.jpg");
export const bg2 = require("./back-new/back-new-2.jpg");
export const bg3 = require("./back-new/back-new-3.jpg");
export const bg4 = require("./back-new/back-new-4.jpg");
export const bg5 = require("./back-new/back-new-5.jpg");
export const bg6 = require("./back-new/back-new-6.jpg");
export const bg7 = require("./back-new/back-new-7.jpg");
export const bg8 = require("./back-new/back-new-8.jpg");
export const bg9 = require("./back-new/back-new-9.jpg");
export const bg10 = require("./back-new/back-new-10.jpg");
export const bg11 = require("./back-new/back-new-11.jpg");

export const bg1v2 = require("./back1.jpg");
export const bg2v2 = require("./back2.jpg");
export const bg3v2 = require("./back3.jpg");
export const bg4v2 = require("./back4.jpg");
export const bg5v2 = require("./back5.jpg");
export const bg6v2 = require("./back6.jpg");
export const bg7v2 = require("./back7.jpg");
export const bg8v2 = require("./back8.jpg");
export const bg9v2 = require("./back9.jpg");
export const bg10v2 = require("./back10.jpg");
export const bg11v2 = require("./back11.jpg");

export const main = require("./main.jpg");
export const logos = require("./logosNoMargin.jpg");
export const mainBack = require("./mainBack.jpg");

// export const bgList = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10, bg11];
// Klient na razie nie chce Piasta i Bossmana
export const bgList = [
  bg1,
  bg2,
  bg3,
  bg4,
  bg5,
  bg6,
  bg7, 
  bg8,
  bg9,
  bg10,
  bg11,
];
export const bgListTemp = [
  bg1v2,
  bg2v2,
  bg3v2,
  bg4v2,
  bg5v2,
  bg6v2,
  /* bg7, */ bg8v2,
  bg9v2,
  bg10v2,
  bg11v2,
];
export const gb1 = require("./greenBack1.jpg");
export const gb2 = require("./greenBack2.jpg");
export const gb3 = require("./greenBack3.jpg");
export const gb4 = require("./greenBack4.jpg");
export const gb5 = require("./greenBack5.jpg");
export const gb6 = require("./greenBack6.jpg");
export const gb7 = require("./greenBack7.jpg");
export const gb8 = require("./greenBack8.jpg");
export const gb9 = require("./greenBack9.jpg");
export const gb10 = require("./greenBack10.jpg");
export const gb11 = require("./greenBack11.jpg");

export const gbList = [gb1, gb2, gb3, gb4, gb5, gb6, gb7, gb8, gb9, gb10, gb11];
// Klient na razie nie chce Piasta i Bossmana
export const gbListTemp = [
  gb1,
  gb2,
  gb3,
  gb4,
  gb5,
  gb6,
  /* gb7, */ gb8,
  gb9,
  gb10,
  gb11,
];
