import axios from "axios";
import { hostCloud, host } from "./host";

const dictionaries = process.env.REACT_APP_STATES;
const url = hostCloud + "/subscribers";

export const postNewsletter = async (userData) => {
  let escapeSpacebarsEmail = userData.email.split(" ").join("");
  let newData = {
    agreementToSendEmail: true,
    companyName: userData.all === true ? true : userData.companyName,
    agreementToSendSms:
      userData.all === true ? true : userData.agreementToSendSms,
    agreementToPhoneContact:
      userData.all === true ? true : userData.agreementToPhoneContact,
    agreementToAutomaticContact:
      userData.all === true ? true : userData.agreementToAutomaticContact,
    agreementToInformationClause: true,
    postCode: userData.postCode,
    city: userData.city,
    street: userData.street,
    houseNumber: userData.houseNumber,
    phoneNumber: userData.phoneNumber,
    email: escapeSpacebarsEmail,
    newsletterId: userData.newsletterId,
    nip: userData.nip,
    firstName: userData.firstName,
    lastName: userData.lastName,
    region: userData.state,
    newsletterCategories: userData.categories,
  };
  return await axios({
    method: "post",
    url: url + "/subscribe",
    headers: {
      "Content-Type": "application/json",
    },
    data: newData,
  });
};

export const unsubscribe = async (token, content) => {
  return await axios({
    method: "post",
    url: url + `/unsubscribe?token=${token}&reason=${content}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: content,
  });
};
export const unsubscribeCheckToken = async (token) => {
  return await axios({
    method: "post",
    url: url + `/unsubscribe/check-token?token=${token}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const unsubscribeMail = async (email, reason) => {
  return await axios({
    method: "post",
    url: url + `/unsubscribe/mail`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      email: email,
      reason: reason,
    },
  });
};
export const unsubscribeRequest = async (email) => {
  return await axios({
    method: "post",
    url: url + `/unsubscribe/request`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      email: email,
    },
  });
};
export const getApprove = async (t) => {
  return await axios({
    method: "put",
    url: url + `/approve?token=${t}`,
    headers: {
      "Content-type": "application/json",
    },
    data: t,
  });
};
export const getStates = async () => {
  return await axios({
    method: "get",
    url:
    host +
      "/restApi/request/model/Slownik/params/" +
      `{"SLO_ID_KATEG":20000002}/pagination/{"page":1,"itemsPerPage":100}`,
    headers: {
      "Content-type": "application/json"
    },
  });
};
export const getNets = async () => {
  return await axios({
    method: "get",
    url:
      host +
      "/restApi/request/model/Slownik/params/" +
      `{"SLO_ID_KATEG":88800182}/pagination/{"page":1,"itemsPerPage":10000}`,
    headers: {
      "Content-type": "application/json",
    },
  });
};
export const getToken = async () => {
  return await axios({
    method: "get",
    url: process.env.REACT_APP_TOKEN,
    headers: {
      "Content-type": "application/json",
    },
  });
};
export const getTokenCP = async () => {
  return await axios({
    method: "get",
    url: host + "/restApi/createGuestToken",
    headers: {
      "Content-type": "application/json",
    },
  });
};
export const getPage = async (token, pageKey) => {
  return await axios({
    method: "get",
    url: `https://carlsberg-qa.bpower2.com/index.php/restApi/request/model/Pages/params/%7B%22%60key%60%22%3A%22${pageKey}%22%2C%20%22lang%22%3A%22pl%22%7D`,
    headers: {
      "Content-type": "application/json",
      Authorization: token,
    },
  });
};
