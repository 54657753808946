// DEV
// export const host = "https://carlsberg-qa.bpower2.com/index.php";
// export const hostCloud = "https://cloud-qa.api.bp2.cloud/cloud_qa";
// export const captchaKey = "6LceYakZAAAAAG7iwQOtUpYX9qilsJzE045xhjry";

// QA
// export const host = "https://carlsberg-qa.bpower2.com/index.php";
// export const hostCloud = "https://cloud-qa.api.bp2.cloud/cloud_qa";
// export const captchaKey = "6Lesig0eAAAAAIbV8HbaSZUKy9IY5flMvFnu9Lv_";

// PROD
export const host = "https://carlsberg.bpower2.com/index.php";
export const hostCloud = "https://api.bp2.cloud:8443/bpower2newsletter";
export const captchaKey = "6LcI0mQhAAAAALD4-3xC_WAtYNnzun0IUF6wWJkj";
