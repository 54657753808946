import React from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import ConfirmEmail from "./ConfirmEmail";
const ConfirmEmailContainer = () => {
  return (
    <>
      <ConfirmEmail />
      <CarlsbergPoland />
    </>
  );
};

export default ConfirmEmailContainer;
