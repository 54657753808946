import React from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import SubscriptionFailed from "./SubscriptionFailed";
const SubscriptionFailedContainer = () => {
  return (
    <>
      <SubscriptionFailed />
      <CarlsbergPoland />
    </>
  );
};

export default SubscriptionFailedContainer;
