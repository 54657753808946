import React from "react";
import CarlsbergPoland from "../carlsbergPoland/CarlsbergPoland";
import MessageSent from "./MessageSent";
const MessageSentContainer = () => {
  return (
    <>
      <MessageSent />
      <CarlsbergPoland />
    </>
  );
};

export default MessageSentContainer;
