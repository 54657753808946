import React from "react";
import "./Switch.css";

const Switch = ({ value, handleToggle, name, color, disabled }) => {
  return (
    <>
      <input
        type="checkbox"
        checked={value}
        onChange={(e) => {
          if (handleToggle) {
            handleToggle(e.target.checked);
          }
        }}
        className="switch-checkbox"
        id={name}
        disabled={disabled || false}
      />
      <label
        className={"switch-label" + (color == "lightgrey" ? " blocked" : "")}
        htmlFor={name}
        style={{ background: color }}
      >
        <span className="switch-button"></span>
      </label>
    </>
  );
};

export default Switch;
