import React from "react";
import styled from "styled-components";
import logo from "../images/carlsberg-polska.png";
//import { HashLink as Link } from "react-router-hash-link";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-light nav-global sticky-top d-flex justify-content-evenly">
      <Link className="navbar-brand logo-box m-0" to="/">
        <Img src={logo} alt="logo" />
      </Link>
      <H2>Newsletter Carlsberg Polska</H2>
    </nav>
  );
};

export default Navbar;
const Img = styled.img`
  width: 86px;
  height: 45px;
  @media (max-width: 576px) {
    margin-left: 15px;
  }
  @media (max-width: 405px) {
    margin-left: 5px;
  }
  @media (max-width: 350px) {
    margin-left: 2px;
  }
`;
const H2 = styled.div`
  top: 0;
  right: 0;
  z-index: 3;
  font-size: 3.2rem;
  font-weight: bold;
  color: #284a41;
  text-align: right;
  padding-right: 15px;

  @media (max-width: 768px) {
    font-size: 2.6rem;
  }
  @media (max-width: 580px) {
    font-size: 2.2rem;
  }
  @media (max-width: 479px) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.6rem;
  }
  @media (max-width: 405px) {
    padding-right: 5px;
  }
  @media (max-width: 362px) {
    font-size: 1.4rem;
  }
  @media (max-width: 350px) {
    padding-right: 2px;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
`;
